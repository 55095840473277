<template>
  <v-container fluid>
    <app-card-1 v-bind="intro" />
    <v-row dense>
      <v-col
        v-for="(card, index) in cards"
        :key="index"
        cols="12"
        md="4"
      >
        <AppCard2 v-bind="card" />
      </v-col>
    </v-row>
    <v-card class="mt-2 py-2">
      <div style="text-align: center;">
        Contact <a href="mailto:luke.lewitzka@knowyourdata.com.au">Luke</a> for enquiries and support.
      </div>
    </v-card>
  </v-container>
</template>

<script>
import AppCard1 from '@/components/ui/landing/hero/Hero5.vue';
import AppCard2 from '@/components/ui/application/appcard/AppCard2.vue';

export default {
	components: {
		AppCard2,
		AppCard1
	},

	data() {
		return {
			intro: {
				heading: 'Cryptique',
				headingPrefix: 'Try',
				body: 'Simplify and track your crypto assests.',
			},

			cards: [
				{
					image: '/images/cryptique/ss1.png',
					avatar: false,
					// name: 'Laura Smith',
				// role: 'Admin',
				// title: 'Senior Software Engineer',
				// phone: 'tel:123',
				// email: '#'
				},
				{
					image: '/images/cryptique/ss2.png',
					avatar: false,
					// name: 'Robert Smith',
				// role: '',
				// title: 'QA',
				// phone: '',
				// email: '#'
				},
				{
					image: '/images/cryptique/ss3.png',
					avatar: false,
					// name: 'Dave Chad',
				// role: 'Moderator',
				// title: 'Senior Product Manager',
				// phone: 'tel:123',
				// email: '#'
				}
			]
		};
	}
};
</script>
